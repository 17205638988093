import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addVariantsToCart,
  getProductsAvailabilityBySkus,
} from "~/external/commercetools";
import { useDataLayer } from "~/hooks/useDataLayer";
import { CtpCtx } from "~/lib/ctpApollo";
import { setCart } from "~/store/cart";
import { addToast } from "~/store/toasts";

/**
 * Hook
 * @param {*} variant
 * @param {*} forceDisabled
 * @param {*} cooldownMs
 */
function useBuyButton(variant, extras, skipCrossSells = false, forceDisabled = false) {
  const dataLayer = useDataLayer("dataLayer");
  const ctpCtx = useContext(CtpCtx);
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const countryData = useSelector((state) => state.countryData);

  let outOfStock = variant.availability.availableQuantity <= 0;
  const disabled = !countryData.shipping.enabled || forceDisabled || outOfStock;
  const [working, setWorking] = useState(false);
  const cooldownTimeout = useRef();

  if (variant.productTypeKey === "parts-bundle") {
    outOfStock = false;
    for (let i = 0; i < variant.parts.length; i++) {
      const part = variant.parts[i];
      if (part.variants[part.masterVariantIndex].availability.availableQuantity <= 0) {
        outOfStock = true;
        break;
      }
    }
  }

  // cooldown is used to display a "added to cart" eg. state on the button
  // const [inCooldown, setInCooldown] = useState(false);
  // make a small timer to shopw the success message

  // when we unmount, clear addedToCartTimeout
  useEffect(() => () => window.clearTimeout(cooldownTimeout.current), []);

  if (!variant) return {};

  async function onClick(e) {
    try {
      e.preventDefault();
      if (working) return;
      setWorking(true);

      const partsBundleContainerSkus = variant.bundle
        ? variant.bundledProducts
            .filter((mv) => mv.typeKey === "parts-bundle")
            .map((bp) => bp.variants[bp.masterVariantIndex])
            .map((mv) => mv.sku)
        : [];

      // check availabilty - product cache might be stale...
      // this _should_ also update the availibity in cache ??
      let skus = variant.bundle
        ? variant.bundledProductsSkus
        : [
            ...(variant.productTypeKey === "parts-bundle" // In case the part is not part of another bundle
              ? variant.parts.map((part) => part.variants[part.masterVariantIndex].sku)
              : [variant.sku]),
          ];

      if (variant.bundle) {
        skus = variant.bundledProductsSkus.filter(
          (sku) => !partsBundleContainerSkus.includes(sku)
        );
      }

      const partsBundles = variant.bundle
        ? [].concat.apply(
            [],
            variant.bundledProducts
              .map((bp) => bp.variants[bp.masterVariantIndex].parts)
              .filter((parts) => parts.length > 0)
          )
        : [];

      const partsBundleSkus = partsBundles.map(
        (part) => part.variants[part.masterVariantIndex].sku
      );

      skus = [
        ...skus,
        ...partsBundleSkus,
        ...extras.map((extra) => extra.variants[extra.masterVariantIndex].sku),
      ];

      const availability = await getProductsAvailabilityBySkus(
        ctpCtx,
        skus,
        countryData
      );

      // IF 0 DISABLE AND MAKE TOAST!!
      if (availability <= 0) {
        dispatch(
          addToast({
            title: `Error`,
            text: `Product out of stock.`,
            unMountMs: false,
            backgroundColor: "tomato",
            color: "white",
          })
        );
        setWorking(false);
        return;
      }

      const updatedCart = await addVariantsToCart(
        ctpCtx,
        cart,
        [variant, ...extras.map((extra) => extra.variants[extra.masterVariantIndex])],
        countryData,
        1
      );

      // Set the updated cart in the store
      dispatch(setCart(updatedCart));

      // Add event to GTM
      dataLayer.push({
        event: "addToCart",
        variant,
      });

      // Show the success
      setWorking(false);

      // Show toast
      const toast = {
        title: `Added to cart`,
        variant,
        extras,
        unMountMs: 5000,
        // unMountMs: false,
        secondaryButton: {
          text: `View cart`,
          href: `/cart`,
        },
        primaryButton: {
          text: `Checkout`,
          href: `/checkout/shipping-address`,
        },
      };

      if (!skipCrossSells) {
        if (typeof document !== "undefined") {
          toast.optimize = document.body.getAttribute("optimize-activated");
          if (toast.optimize) {
            toast.primaryButton.text = toast.secondaryButton.text;
            toast.primaryButton.href = toast.secondaryButton.href;
            toast.secondaryButton = {
              text: `Continue shopping`,
              href: ``,
            };
          }
        }
      }

      dispatch(addToast(toast));
    } catch (e) {
      console.error(e);
      setWorking(false);
    }
  }

  return { onClick, disabled, working };
}

export default useBuyButton;
