import { IncomingMessage } from "http";
import getConfig from "next/config";
import Prismic from "prismic-javascript";
import { RichText } from "prismic-reactjs";
import {
  getProductCategoriesByKeys,
  getProductsByCategoryIds,
  getProductsByKeys,
} from "~/external/commercetools";
import cache from "~/lib/cache";
import { resolveProducts } from "~/lib/ctp/resolveProduct";
import resolveVariantLink from "~/lib/resolveVariantLink";
import theme from "~/styles/theme";
import { Navigation } from "./prismic.types";

// import routes from 'data/routes';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

let frontClient;

export const PrismicClient = (req: IncomingMessage | undefined = undefined) => {
  if (!req && frontClient) return frontClient;
  // prevent generate new instance for client side since we don't need the refreshed request object.

  const options = {
    ...(req ? { req } : {}),
    ...(publicRuntimeConfig.PRISMIC_ACCESS_TOKEN
      ? { accessToken: publicRuntimeConfig.PRISMIC_ACCESS_TOKEN }
      : {}),
  };
  return Prismic.client(publicRuntimeConfig.PRISMIC_ENDPOINT, options);
};
// export const Prismic = PrismicLib;

export const linkResolver = (doc) => {
  console.log("linkResolver", doc);
  switch (doc.type) {
    case "page":
      return `/${doc.uid}`;
    case "story_category":
      return `/stories/${doc.uid}`;
    case "story":
      return `/stories/~/${doc.uid}`; // TODO: RESOLVE CORRECTLY
    case "artist":
      return `/artists/${doc.uid}`;
    case "review":
      return `/reviews/${doc.uid}`;
    case "question_category":
      return `/help/${doc.uid}`;
    case "question":
      return `/help/~/${doc.uid}`; // TODO: RESOLVE CORRECTLY
    default:
      return doc.uid ? `/${doc.uid}` : "/";
  }
};

function checkEntryForType(entry, type) {
  if (entry) {
    // check iof that dataEntry is a product...
    if (entry.type === type) return true;
  }
  return false;
}

/**
 * get "entries" of a certain type from a document
 * usage: `getEntriesOfType(document, 'product)`
 * returns an array
 */
export function getEntriesOfType(document, type) {
  let entries = [];

  const { data } = document;

  const { body, ...restOfData } = data;
  // body = the slices
  // restOfData = fields that are not slices

  // Check all fields that is not the body
  Object.values(restOfData).forEach((entry) => {
    if (checkEntryForType(entry, type)) entries.push(entry);
  });

  // check the body!
  body.forEach((slice) => {
    const { primary, items } = slice;
    // items = repeatable fields
    // primary = not-repeatable fields

    // first check primary (non-repeatable)
    Object.values(primary).forEach((entry) => {
      if (checkEntryForType(entry, type)) {
        entries.push(entry);
      }
    });

    // then check the items (repeatble-fields)
    items.forEach((item) => {
      Object.values(item).forEach((entry) => {
        if (checkEntryForType(entry, type)) {
          entries.push(entry);
        }
      });
    });
  });

  // remove dupes (nasty reduce function...)
  entries = entries.reduce((acc, entry) => {
    if (!acc.find((e) => e.id === entry.id)) {
      acc.push(entry);
    }
    return acc;
  }, []);

  return entries;
}

/**
 * Get anchors (anchor_text and anchor_id) from a prismic page
 * @param {*} page
 */
export function getAnchors(page) {
  const anchors = [];

  const { body = [] } = page.data;

  // check all slices for anchors
  body.forEach((slice) => {
    const { primary = {} } = slice;

    // check the primary fields
    // no need to check items
    if (primary.anchor_text && primary.anchor_id) {
      anchors.push([primary.anchor_id, primary.anchor_text]);
    }
  });
  return anchors;
}

// used by resolveMedia
export function getMediaArrayFromImage(image, disableCompression = false) {
  if (!image.url) return [null, "image", null, null, image.alt, null];

  let src = image.url;

  // Remove "?auto=format,compress" from the URL if disableCompression is true
  if (disableCompression) {
    const urlWithoutParams = src.split("?")[0]; // Strip query parameters
    const params = new URLSearchParams(src.split("?")[1] || "");

    // Remove auto-related params
    params.delete("auto");

    // Rebuild the URL without the "auto=format,compress" part
    src = params.toString()
      ? `${urlWithoutParams}?${params.toString()}`
      : urlWithoutParams;
  }

  return [
    src,
    "image",
    image.dimensions.width,
    image.dimensions.height,
    image.alt,
    null,
  ];
}

// used by resolveMedia
export function getMediaArrayFromVideo(
  video,
  aspectRatio = "16:9",
  placeholder = null
) {
  const [width, height] = aspectRatio.split(":");
  return [
    video.url,
    "video",
    width,
    height,
    null,
    placeholder && placeholder.url ? placeholder.url : null,
  ];
}

/**
 * Resolve image or video into a "media" object (used by compoonents)
 * @param {*} item
 * @param {*} defaultAspectRatio
 */
export function resolveMedia(
  item,
  defaultAspectRatio = "16:9",
  imageSize = "large",
  mobile = false,
  disableCompression = false
) {
  let media = null;
  let { image = {}, video = {}, video_aspect_ratio } = item;
  let aspectRatio = video_aspect_ratio || defaultAspectRatio;

  if (mobile) {
    image = item.image_mobile || {};
    video = item.video_mobile || {};
    aspectRatio = item.video_aspect_ratio_mobile || aspectRatio;
  }

  // video as precedence over image
  // check if primary.video has an url...
  if (image.url || video.url) {
    media = video.url
      ? getMediaArrayFromVideo(video, aspectRatio, image[imageSize] || image)
      : getMediaArrayFromImage(image[imageSize] || image, disableCompression);
  }

  if (image.url) {
    // if there wasn't an image alt value for the desired size, try the default image
    if (!media[4]) {
      media[4] = image.alt;
    }
    // if it's video, check if it should be muted or not
    if (video.url) {
      media[6] = item.muted;
    }
  }

  return media;
}

/**
 * Get seo for a prismic page
 */
export function getSeo(page) {
  // console.log(page);
  const seo = {
    title: page.data.seo_title || "AIAIAI Audio",
    description: page.data.seo_description || "",
    keywords: page.data.seo_keywords || "",
    index: page.data.seo_index_page === "No" ? "noindex" : "index",
    follow: page.data.seo_follow_links === "No" ? "nofollow" : "follow",
  };
  // console.log('seo', seo);
  return seo;
}

/**
 * Get products based on product entries in prismic
 * @param {*} productEntries
 * @param {*} ctpCtx
 * @param {*} countryData
 */
export async function getProducts(productEntries, ctpCtx, countryData) {
  if (!countryData || !countryData.currency || !countryData.channel) {
    console.error("Invalid or incomplete country data provided", countryData);
    return [];
  }

  try {
    let products = [];

    if (productEntries.length > 0) {
      const keys = productEntries.map((p) => p.data.key).filter((key) => !!key);
      products = await getProductsByKeys(ctpCtx, keys, countryData);
    }

    const resolvedProducts = await resolveProducts(ctpCtx, products, countryData);
    return resolvedProducts;
  } catch (err) {
    console.log("prismic->getProducts error", err);
    throw err;
  }
}

/**
 * Get categories based on categoryEntries from prismic
 * @param {*} categoryEntries
 * @param {*} ctpCtx
 * @param {*} countryData
 */
export async function getCategories(categoryEntries, ctpCtx, countryData) {
  try {
    let categories = [];
    /*
    {
      id: '...',
      key: 'speaker-units',
      products: [ ... ]
    }
    */

    if (categoryEntries.length > 0) {
      const keys = categoryEntries.map((p) => p.data.key);
      const productCategories = await getProductCategoriesByKeys(ctpCtx, keys);

      categories = (productCategories ?? []).map((category) => ({
        key: category.key,
        id: category.id,
        products: [],
      }));

      /* const getCategories = [];

      for (let key of keys) {
        getCategories.push(
          new Promise(resolve => {
            getProductCategoryByKey(ctpCtx, key).then(category =>
              resolve({ key: key, id: category.id, products: [] })
            );
          })
        );
      }

      categories = await Promise.all(getCategories); */

      // console.log(categories)
      const ids = productCategories.map((category) => category.id);
      // console.log('IDS', ids);
      const products = await getProductsByCategoryIds(ctpCtx, ids, countryData);

      /* const getCategoryProducts = [];

      for (let category of categories) {
        getCategoryProducts.push(
          new Promise(resolve => {
            getProductsByCategoryId(ctpCtx, category.id, countryData).then(
              products => {
                // resolve them products, so ther are usable via our helper functions
                const resolveProducts = [];
                for (let product of products) {
                  resolveProducts.push(
                    new Promise(resolve => {
                      resolveProduct(ctpCtx, product, countryData).then(pr =>
                        resolve(pr)
                      );
                    })
                  );
                }
                Promise.all(resolveProducts).then(products => {
                  resolve({ ...category, products });
                });
              }
            );
          })
        );
      }

      // products?
      categories = await Promise.all(getCategoryProducts); */

      // console.log("PRODUCTS =>", JSON.stringify(products, null, 2));

      const resolvedProducts = await resolveProducts(ctpCtx, products, countryData);

      for (const resolvedProduct of resolvedProducts) {
        for (const productCategory of resolvedProduct.categories) {
          const category = categories.find(
            (category) => category.id === productCategory.id
          );
          category.products.push(resolvedProduct);
        }
      }

      /* for (let product of products) {
        for (let productCategory of product.masterData.current.categories) {
          let category = categories.find(category => category.id === productCategory.id);
          let resolvedProduct = await resolveProduct(ctpCtx, product, countryData);
          category.products.push(resolvedProduct);
        }
      } */

      // console.log("CATEGORIES =>", categories)
    }

    return categories;
  } catch (err) {
    console.log(err.toString());
    throw err;
  }
}

// export function richTextIsEmpty(richText){

//   if(!richText) return true;

//   if(richText.length === 0) return true;

//   // check each entry, if they are empty!
//   let isEmpty = true;
//   richText.forEach(i => {
//     if(i.text.length > 0){
//       isEmpty = false;
//     }
//   })

//   return isEmpty;

// }

// check if a rich text field is empty...
export const richTextIsEmpty = (t) => {
  if (!t) return true;
  return t.length === 0 ? true : t[0].text.length === 0;
};

export function getRichText(richText) {
  // console.log('RICH', richText);
  if (!richText) return null;

  // check if there is any text...
  if (richText.length === 0) {
    return null;
  }

  // check if it's actually an empty string..
  // DOES NOT WORK FOR IMAGES ONLY
  try {
    if (richText.length === 1 && richText[0].text.length === 0) {
      return null;
    }
  } catch (err) {}

  return <RichText render={richText} linkResolver={linkResolver} />;
}

export function getSpacing(spacingField) {
  switch (spacingField) {
    case "Full":
    case "Yes": // fallback to old
      return 13;
    case "Half":
      return 6;
    case "None":
    case "No": // fallback to old
    default:
      return 0;
  }
}

/**
 * gets the most common fields from a slice...
 */
export function getCommonSliceProps(slice = {}, pageTheme = "light") {
  const { primary = {} } = slice;

  const props = {};
  // spacingTop
  if (primary.spacing_top) props.spacingTop = getSpacing(primary.spacing_top);
  // spacingBottom
  if (primary.spacing_bottom) props.spacingBottom = getSpacing(primary.spacing_bottom);
  // backgroundColor
  if (primary.background_color)
    props.backgroundColor = primary.background_color || theme.colors.light;
  // color
  if (primary.text_color) props.color = primary.text_color || theme.colors.dark;
  // hightlightColor
  if (primary.highlight_color)
    props.highlightColor = primary.highlight_color || theme.colors.primary;
  if (typeof primary.highlight_outline !== "undefined")
    props.highlightOutline = primary.highlight_outline;
  // animateIn
  if (primary.animate_in) props.animateIn = primary.animate_in.toLowerCase() === "yes";
  // anchorText
  if (primary.anchor_text) props.anchorText = primary.anchor_text;
  // anchorPreText
  if (primary.anchor_pre_text) props.anchorPreText = primary.anchor_pre_text;
  // anchorId
  if (primary.anchor_id) props.anchorId = primary.anchor_id;
  // link
  if (primary.link) {
    const [href, hrefAs, hrefTarget] = resolveLink(primary.link);
    props.href = href;
    props.hrefAs = hrefAs;
    props.hrefTarget = hrefTarget;
  }

  return props;
}

export function getQuoteProps(item = {}) {
  return {
    renderAsQuote: !!item.render_as_quote,
    quoteSource: getRichText(item.quote_source),
    quoteSourceTextColor: item.quote_source_text_color,
    quoteSourceAlignment: item.quote_source_alignment ?? "right",
    quoteDecal: getRichText(item.quote_decal),
    quoteDecalOutline: !!item.quote_decal_outline,
    quoteDecalPlacement: item.quote_decal_placement ?? "none",
  };
}

/**
 * A document link will load the document into fields of the slice. NOT link to a document
 */
export function resolveDocumentLink(item) {
  const { data } = item.document_link;

  const {
    document_link,
    image = {},
    title,
    subtitle,
    text = [],
    link = {},
    button_text,
  } = item;

  switch (document_link.type) {
    case "artist":
      // image
      if (!image.hasOwnProperty("url")) item.image = data.featured_image;
      // title
      if (!title) item.title = data.name;
      // subtitle
      if (!subtitle) item.subtitle = data.profession;
      // text
      if (richTextIsEmpty(text)) item.text = data.short_description;
      break;
    case "story":
      // image
      if (!image.hasOwnProperty("url")) item.image = data.cover_image;
      // title
      if (!title) item.title = data.title;
      // subtitle
      if (!subtitle) item.subtitle = data.category.slug;
      // text
      // if (richTextIsEmpty(text)) item.text = data.short_description;
      // link
      // TODO: Is this the best way to do this? Feels a bit hacked
      if (link.link_type === "Any") item.link = item.document_link;
      break;
    case "review":
      // image
      if (!image.hasOwnProperty("url")) item.image = data.reviewer_logo;
      // title
      if (!title) item.title = data.reviewer;
      // text
      if (richTextIsEmpty(text)) item.text = data.quote;
      // link
      if (link.link_type === "Any") item.link = data.link;
      // button text
      if (!button_text) item.button_text = "Full review";
      break;
    case "reseller":
      // image
      if (!image.hasOwnProperty("url")) item.image = data.logo;
      // title
      if (!title) item.title = data.name[0].text;
      break;
  }

  return item;
}

export async function getSoundProfile(soundProfileUid) {
  const type = "sound_profile";
  const value = cache.get(`${type}|${soundProfileUid}`);
  if (value) return value;

  try {
    const soundProfile = await PrismicClient().getByUID(type, soundProfileUid);

    const { data } = soundProfile;

    cache.set(`${type}|${soundProfileUid}`, data);

    return data;
  } catch (err) {
    console.error(`Sound profile did not exist for ${soundProfileUid}`);
    return null;
  }
}

export function getModularPartTypeFromTags(tags) {
  if (tags.includes("speaker-units")) return "speaker-units";
  if (tags.includes("headband")) return "headband";
  if (tags.includes("earpads")) return "earpads";
  if (tags.includes("cable")) return "cable";
  return null;
  // if (tags.includes('speaker-units')) return ['speaker-units', 'speaker-units'];
  // if (tags.includes('headband')) return ['headband', 'headbands'];
  // if (tags.includes('earpads')) return ['earpads', 'earpads'];
  // if (tags.includes('cable')) return ['cable', 'cables'];
  // return [null, null];
}

export function resolveLink(link) {
  const { link_type: linkType, type: documentType, tags, data = {} } = link;

  try {
    switch (linkType) {
      case "Document":
        // handle the different document types...
        switch (documentType) {
          // PAGE
          case "page":
            // special case if we link to "tma-2-build-your-own"
            if (link.uid === "tma-2-build-your-own") {
              return [
                `/headphones/tma-2-build-your-own/[serial]`,
                `/headphones/tma-2-build-your-own/s02h02e02c02`,
                link.target || "_self",
              ];
            }

            switch (data.page_type) {
              case "/headphones":
                return [
                  `/headphones/[...slug]`,
                  `/headphones/${link.uid}`,
                  link.target || "_self",
                ];
              case "/studio-monitors":
                return [
                  `/studio-monitors/[...slug]`,
                  `/studio-monitors/${link.uid}`,
                  link.target || "_self",
                ];
              case "/accessories":
                return [
                  `/accessories/[...slug]`,
                  `/accessories/${link.uid}`,
                  link.target || "_self",
                ];
              default:
                return [`/[prismicUid]`, `/${link.uid}`, link.target || "_self"];
            }

            break;
          // PRODUCT
          case "product":
            if (tags.includes("modular-part")) {
              // a modular part
              return Object.values(
                resolveVariantLink(
                  {
                    productTypeKey: getModularPartTypeFromTags(tags),
                    productKey: link.uid,
                  },
                  link.target || "_self"
                )
              );
            }
            if (tags.includes("accessory")) {
              // an accessory
              return Object.values(
                resolveVariantLink(
                  {
                    productTypeKey: "accessory",
                    productKey: link.uid,
                    key: data.variant_slug,
                  },
                  link.target || "_self"
                )
              );
            }
            // TODO: Support studio monitors
            // assume it is just a headphone
            return Object.values(
              resolveVariantLink(
                {
                  productTypeKey: "headphone",
                  productKey: link.uid,
                  key: data.variant_slug,
                },
                link.target || "_self"
              )
            );
          case "variant":
            const serial = data.parts.map(({ part }) => part.uid).join("");
            return Object.values(
              resolveVariantLink(
                {
                  productTypeKey: "tma-2-configuration",
                  serial,
                },
                link.target
              )
            );
          // STORIES
          // TODO: Is this the best way to do this? Feels a bit hacked
          case "story":
            return [
              `/stories/[category]/[story]`,
              `/stories/${link.data.category.uid}/${link.uid}`,
              link.target || "_self",
            ];
        }
        break;
      case "Web":
      case "Media":
        // href = link.url;
        // if (link.target) target = link.target;
        return [link.url, link.url, link.target || "_self"];
    }
    // console.log(href, hrefAs, target);
    return [undefined, undefined, undefined];
  } catch (err) {
    console.log(err);
    console.warn("could not resolve link", link, err);
    return [undefined, undefined, undefined];
  }
}

/**
 * fetches a page from prismic
 */
export async function getPage(req, pageUid, fetchLinks = true) {
  const type = "page";
  // console.log('pageUid', pageUid)
  const value = cache.get(`${type}|${pageUid}|links:${fetchLinks}`);
  if (value) return value;

  const page = await PrismicClient(req).getByUID(type, pageUid, {
    fetchLinks: fetchLinks
      ? [
          // product
          "product.sku",
          "product.key",
          "product.variant_slug",
          // category
          "category.key",
          // artist
          "artist.featured_image",
          "artist.name",
          "artist.profession",
          "artist.short_description", // richtext, 1 paragraph
          // review
          "review.title",
          "review.reviewer",
          "review.reviewer_logo",
          "review.quote", // richtext, 1 paragraph
          "review.link",
          // variant
          "variant.parts",
          "variant.description",
          "variant.product_title",
          "variant.image",
          // page
          "page.page_type",
          "page.title",
          "page.parent",
          // sound profile
          "sound_profile.frequency_low",
          "sound_profile.frequency_mid",
          "sound_profile.frequency_high",
          "sound_profile.response_curve_data",
          "sound_profile.response_curve_y_axis",
          "sound_profile.speaker_units",
          // questions
          "question.title",
          "question_category.title",
          // resellers
          "reseller.name",
          "reseller.group",
          "reseller.country_code",
          "reseller.website",
          "reseller.logo",
          "reseller.active",
          "reseller.locations",
          // stories
          "story.title",
          "story.date",
          "story.short_description",
          "story.cover_image",
          "story.category",
          "story_category.title",
        ]
      : [],
  });

  // console.log('page', JSON.stringify(page, null, 2));

  cache.set(`${type}|${pageUid}|links:${fetchLinks}`, page); // , 5 * 60);

  return page;
}

/**
 * fetches a navigation from prismic (with subnav)
 */
export async function getNavigation(
  req: IncomingMessage | undefined,
  navUid: string
): Promise<Navigation> {
  const type = "navigation";
  const cacheKey = `${type}|${navUid}`;

  let navigation = cache.get(cacheKey);
  if (!navigation) {
    // fetch nav
    const nav = await PrismicClient(req).getByUID(type, navUid);

    // get navigation items
    const menuItems = nav.data.body; // array

    const ribbon = {
      active: nav.data.ribbon_active || false,
      text: {
        value: nav.data.ribbon_text,
        color: nav.data.ribbon_color_text,
        colorHighlight: nav.data.ribbon_color_highlight,
      },
      background: { color: nav.data.ribbon_color_background },
      link: nav.data.ribbon_link,
      button_text: nav.data.ribbon_button_text || false,
      countdown: nav.data.ribbon_countdown || false,
    };

    // each items have a "items" key, where they can have submenus! fetch them??
    // const fetchSubNavs = [];

    // for each main menu
    const fetchMenus = menuItems.map(async (menuItem) => {
      // console.log(menuItem);
      const menu = {
        ...menuItem.primary,
      };
      // attempt to fetch sub menus!
      const subMenuItems = menuItem.items;
      if (subMenuItems.length > 0) {
        const fetchSubNavs = subMenuItems.map(async (subMenuItem) => {
          // console.log(subMenuItem);
          const fetchSubMenu = await PrismicClient(req).getByUID(
            type,
            subMenuItem.sub_navigation.uid
          );
          const subMenuItemMenuItems = fetchSubMenu.data.body.map((b) => b.primary);
          return {
            label: subMenuItem.sub_title,
            hasImages: subMenuItem.has_images || false,
            items: subMenuItemMenuItems,
          };
        });
        const subNavs = await Promise.all(fetchSubNavs);
        menu.subNavs = subNavs;
      }
      return menu;
    });

    const items = await Promise.all(fetchMenus);

    navigation = {
      ribbon,
      items,
    };
    cache.set(cacheKey, navigation);
  }
  return navigation as Navigation;
}
