import { getProductsByIds } from "~/external/commercetools";
import resolveTma2, { resolveTma2s } from "~/lib/ctp/resolveTma2";
import resolveVariant from "~/lib/ctp/resolveVariant";

export async function resolveProducts(context, products, countryData) {
  const resolvedProducts = [];
  const resolveTma2List = [];
  for (const product of products) {
    if (product.productType.key === "tma-2-configuration") {
      throw new Error(
        "Cannot resolve configuration in resolveProducts. Use resolveTma2s directly"
      );
    }

    // if it's a bundle
    if (product.productType.key === "tma-2-bundle") {
      resolveTma2List.push(product);
      continue;
    }

    // it's just a product :)
    const variants = [];

    if (product.productType.key === "parts-bundle") {
      const partIds = product.masterData.current.masterVariant.attributesRaw
        .find((a) => a.name === "parts-bundle-parts")
        ?.value.map((p) => p.id);
      const partsProducts = await getProductsByIds(context, partIds, countryData);
      const partsProductsResolved = await resolveProducts(
        context,
        partsProducts,
        countryData
      );

      product.masterData.current.masterVariant.parts = partsProductsResolved;
    }

    // add the master variant
    variants.push(
      resolveVariant(product, product.masterData.current.masterVariant, countryData)
    );

    // add other variants, if any
    for (const variant of product.masterData.current.variants) {
      const resolvedVariant = resolveVariant(product, variant, countryData);
      variants.push(resolvedVariant);
    }

    resolvedProducts.push({
      key: product.key,
      id: product.id,
      typeKey: product.productType.key,
      name: product.masterData.current.name,
      description: product.masterData.current.description,
      categories: product.masterData.current.categories,
      variants,
      masterVariantIndex: 0,
      attributesRaw: product.masterData.current.allVariants[0].attributesRaw,
    });
  }

  if (resolveTma2List.length === 0) return resolvedProducts;

  // TODO: Is order important?

  const resolvedTma2s = await resolveTma2s(context, resolveTma2List, countryData);

  resolvedProducts.push(...resolvedTma2s);

  return resolvedProducts;
}

/**
 * Makes a product usable and extract the important stuff!
 * If a bundle is the product, we will fetch all the related products?
 * TODO: should pull out variants? important. we have to combine masterVariant with variants...
 */
async function resolveProduct(ctpCtx, pr, countryData) {
  try {
    if (!pr) {
      throw new Error("No product..");
    }

    if (pr.productType.key === "tma-2-configuration") {
      throw new Error(
        "Cannot resolve configuration in resolveProduct. Use resolveTma2 directly"
      );
    }

    // if it's a bundle
    if (pr.productType.key === "tma-2-bundle") {
      // return await resolveBundle(ctpCtx, pr, countryData);
      return await resolveTma2(ctpCtx, pr, countryData);
    }

    if (pr.productType.key === "parts-bundle") {
      const partIds = pr.masterData.current.masterVariant.attributesRaw
        .find((a) => a.name === "parts-bundle-parts")
        ?.value.map((p) => p.id);

      const partsProducts = await getProductsByIds(ctpCtx, partIds, countryData);

      const partsProductsResolved = await resolveProducts(
        ctpCtx,
        partsProducts,
        countryData
      );

      pr.masterData.current.masterVariant.parts = partsProductsResolved;
    }

    // it's just a product :)
    const variants = [];
    // add the master variant
    variants.push(resolveVariant(pr, pr.masterData.current.masterVariant, countryData));

    // add other variants, if any
    for (const variant of pr.masterData.current.variants) {
      const resolvedVariant = resolveVariant(pr, variant, countryData);
      variants.push(resolvedVariant);
    }

    const product = {
      key: pr.key,
      id: pr.id,
      typeKey: pr.productType.key,
      name: pr.masterData.current.name,
      description: pr.masterData.current.description,
      variants,
      masterVariantIndex: 0,
      attributesRaw: pr.masterData.current.allVariants[0].attributesRaw,
    };

    return product;
  } catch (err) {
    // console.error('resolveProduct', pr);
    // console.error(err);
    console.warn("Could not find product", err, pr);
    throw err;
  }
}

export default resolveProduct;
