import { createMyCart, getActiveMyCartRest, getMyCart } from "~/external/commercetools";

// Resolves the user's active cart or creates a new one if needed
async function resolveCart(ctx = {}, full = false) {
  if (!ctx.countryData) {
    throw new Error("🛒 resolveCart: Missing countryData in context.");
  }

  const { countryData, ctpCtx } = ctx;

  // If shipping is not enabled for the country, no cart should be created
  if (!countryData.shipping.enabled) {
    return null;
  }

  try {
    // Fetch active cart
    let cart = await getActiveMyCartRest(ctx, countryData);

    // If `full` is requested, get the detailed cart data via GraphQL
    if (cart && full) {
      cart = await getMyCart(ctpCtx, cart.id, countryData);
    }

    // Validate cart state
    if (cart && cart.cartState !== "Active") {
      console.warn(`🛒 resolveCart: Found cart (${cart.id}), but it is not active.`);
      cart = null;
    }

    // Create a new cart if none exists
    if (!cart) {
      cart = await createMyCart(ctpCtx, countryData);
      console.info(
        `🛒 New cart created ${cart.id} for user ${ctx.ctpTokenInfo.access_token}`
      );
    }

    // Mark cart as "slim" if `full` data is not requested
    if (cart) {
      cart.slim = !full;
    }

    return cart;
  } catch (error) {
    console.error("resolveCart: Error resolving cart:", error);
    return null;
  }
}

export default resolveCart;
